import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/video"
import CookieLaw from "../components/cookielaw"

const IndexPage = () => (
  <Layout>
    <CookieLaw />
    <SEO title="Stasi Group - QR PCO Landing Page" />
    <Video
        videoSrcURL="https://www.youtube.com/embed/fSjgAyBaJZk"
        videoTitle="Nuovo impianto di sanificazione dell'aria di Villa Vittoria e Tenuta Leone con tecnologia PCO"
    />
    <h1>Il massimo della sicurezza per i nostri collaboratori e ospiti. </h1>
    <p>Siamo fieri di presentarvi il frutto di un lavoro che è iniziato durante il lockdown. </p>
    <p>Costantemente alla ricerca della qualità, ci siamo imbattuti in questa brillante idea che oggi è realtà: il nuovo sistema di sanificazione dell'aria con tecnologia a flusso catalizzatore, installato sia a Villa Vittoria che a Tenuta Leone. </p>
    <p>La tecnologia PCO (PhotoCatalytic Oxidation - ossidazione fotocatalitica), della Saba Technology, sanifica continuamente i nostri ambienti di produzione e lavoro. </p>
    <p>Nelle nostre location, troverete installate varie colonnine con macchine elettroniche portatili, che agiscono insieme alla stessa tecnologia installata anche nei condotti di aerazione. </p>
    <p>I nostri ospiti potranno così godersi le giornate di festa con la massima serenità, tranquillità e gioia.</p>
  </Layout>
)

export default IndexPage
