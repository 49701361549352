export default {
  dialog: {
    position: 'fixed',
    top: '0',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,.8)',
    width: '100%',
    height: '100%',
    zIndex: '1000',
  },
  container: {
    backgroundColor: '#f8f7f7',
    maxWidth: '500px',
    padding: '16px 32px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
    margin: '10px'
  },
  message: {
    minHeight: '32px',
    fontSize: '10pt',
    fontWeight: '400',
    lineHeight: '130%',
    padding: '10px 0',
    color: '#111111',
  },
  policy: {
    display: 'block',
    fontSize: '10pt',
    color: '#111111',
    margin: '0px 0px 16px',
    textDecoration: 'underline',
  },
  selectPane: {
    display: 'none',
    margin: '0 auto',
    padding: '3px 0px 10px 0px',
  },
  optionWrapper: {
    display: 'inline-block',
    position: 'relative',
    marginRight: '7px',
    marginLeft: '7px',
    float: 'left',
  },
  optionLabel: {
    height: 'auto',
    width: 'auto',
    minHeight: '14px',
    fontSize: '12pt',
    color: '#111111',
    display: 'table',
    margin: '0 auto',
    padding: '1px 0 0 20px',
    position: 'relative',
    top: '0',
    left: '0',
    zIndex: '1',
    cursor: 'default',
    verticalAlign: 'top',
  },
  checkbox: {
    position: 'absolute',
    top: '4px',
    left: '0',
    width: '14px',
    height: '14px',
    zIndex: '2',
    cursor: 'pointer',
  },
  button: {
    display: 'block',
    width:"100%",
    backgroundColor: '#111111',
    padding: '16px 0',
    border: '1px solid #111111 ',
    minWidth: '80px',
    color: '#ffffff',
    textDecoration: 'none',
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'uppercase',
    marginBottom: '8px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
  buttonOutline: {
    display: 'block',
    width:"100%",
    border: '1px solid #111111 ',
    padding: '14px 0',
    color: '#111111',
    textDecoration: 'none',
    fontSize: '14px',
    fontWeight: '400',
    marginBottom: '8px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
};
