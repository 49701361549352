import React from "react"
import { CookieBanner } from "../react-cookie-law/src"
import SettingsIcon from "../icons/settings.svg"

const removeCookies = () => {
  document.cookie = "rcl_consent_given= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
  document.cookie = "rcl_preferences_consent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
  document.cookie = "rcl_statistics_consent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
  document.cookie = "rcl_marketing_consent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
  window.location.reload();
}
const CookieLaw = () => {
    return (
    <>
      <CookieBanner 
        policyLink="/privacy-policy/"
        privacyPolicyLinkText="Cookie & Privacy Policy"
        message="Questo sito utilizza cookie tecnici e di profilazione propri e di terze parti per le sue funzionalità e per inviarti pubblicità e servizi in linea con le tue preferenze. Clicca su uno dei pulsanti per accettare o rifiutare i cookie. Per accettare solamente alcuni cookie, clicca personalizza."
        necessaryOptionText="Essenziali"
        preferencesOptionText="Preferenze"
        statisticsOptionText="Statistiche"
        marketingOptionText="Marketing"
        acceptButtonText="Accetta"
        acceptAllButtonText="Accetta tutti"
        declineButtonText="Rifiuta tutti"
        optionsButtonText="Personalizza"
        showDeclineButton={true}
        dismissOnScroll={false}
        showPreferencesOption={false}
        showStatisticsOption={true}
        showMarketingOption={true}
        
      />
      <button aria-label="remove-cookie" onClick={removeCookies} id="remove-cookies"><SettingsIcon className="settings-icon"/></button>
    </>
    );
}
export default CookieLaw